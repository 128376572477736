// /Projects/sl-site/frontend/src/pages/Home.tsx

import React from 'react';
import Slide from './web/Home/Slide';
import Services from './web/Home/Services';
import PromoSection from './web/Home/PromoSection';
import BlogPostsSection from './web/Home/BlogPostsSection';

const Home: React.FC = () => {
  return (
    <div className="home-container bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text">
      <Slide />
      <div className="container mx-auto p-4">
        <Services />
        <PromoSection />
      </div>
      <BlogPostsSection />
    </div>
  );
};

export default Home;

