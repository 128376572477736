// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* /frontend/src/pages/web/News/News.css */

.news-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .news-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-text);
  }
  
  .news-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  
  .news-card {
    background-color: var(--color-card-background);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid var(--color-border);
  }
  
  .news-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .news-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .news-content {
    padding: 1rem;
  }
  
  .news-card-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--color-text);
  }
  
  .news-card-excerpt {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: var(--color-text);
  }
  
  .news-card-footer {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: var(--color-secondary);
  }
  
  .news-card-author,
  .news-card-date {
    margin: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/web/News/News.css"],"names":[],"mappings":"AAAA,0CAA0C;;AAE1C;IACI,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,wBAAwB;EAC1B;;EAEA;IACE,aAAa;IACb,SAAS;IACT,2DAA2D;EAC7D;;EAEA;IACE,8CAA8C;IAC9C,kBAAkB;IAClB,gBAAgB;IAChB,qDAAqD;IACrD,qCAAqC;EACvC;;EAEA;IACE,2BAA2B;IAC3B,0CAA0C;EAC5C;;EAEA;IACE,WAAW;IACX,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,qBAAqB;IACrB,wBAAwB;EAC1B;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,wBAAwB;EAC1B;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,6BAA6B;EAC/B;;EAEA;;IAEE,SAAS;EACX","sourcesContent":["/* /frontend/src/pages/web/News/News.css */\n\n.news-container {\n    max-width: 1200px;\n    margin: 0 auto;\n  }\n  \n  .news-title {\n    font-size: 2.5rem;\n    text-align: center;\n    margin-bottom: 2rem;\n    color: var(--color-text);\n  }\n  \n  .news-grid {\n    display: grid;\n    gap: 2rem;\n    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  }\n  \n  .news-card {\n    background-color: var(--color-card-background);\n    border-radius: 8px;\n    overflow: hidden;\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n    border: 1px solid var(--color-border);\n  }\n  \n  .news-card:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);\n  }\n  \n  .news-image {\n    width: 100%;\n    height: 200px;\n    object-fit: cover;\n  }\n  \n  .news-content {\n    padding: 1rem;\n  }\n  \n  .news-card-title {\n    font-size: 1.5rem;\n    margin-bottom: 0.5rem;\n    color: var(--color-text);\n  }\n  \n  .news-card-excerpt {\n    font-size: 1rem;\n    margin-bottom: 1rem;\n    color: var(--color-text);\n  }\n  \n  .news-card-footer {\n    display: flex;\n    justify-content: space-between;\n    font-size: 0.875rem;\n    color: var(--color-secondary);\n  }\n  \n  .news-card-author,\n  .news-card-date {\n    margin: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
