// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* /frontend/src/pages/web/News/NewsDetail.css */

.news-detail-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--color-card-background);
    border-radius: 8px;
    border: 1px solid var(--color-border);
  }
  
  .news-detail-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--color-text);
  }
  
  .news-detail-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .news-detail-content {
    font-size: 1rem;
    color: var(--color-text);
  }
  
  .news-detail-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 0.875rem;
    color: var(--color-secondary);
  }
  
  .news-detail-author,
  .news-detail-date {
    margin: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/web/News/NewsDetail.css"],"names":[],"mappings":"AAAA,gDAAgD;;AAEhD;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,8CAA8C;IAC9C,kBAAkB;IAClB,qCAAqC;EACvC;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,wBAAwB;EAC1B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,wBAAwB;EAC1B;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,mBAAmB;IACnB,6BAA6B;EAC/B;;EAEA;;IAEE,SAAS;EACX","sourcesContent":["/* /frontend/src/pages/web/News/NewsDetail.css */\n\n.news-detail-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 2rem;\n    background-color: var(--color-card-background);\n    border-radius: 8px;\n    border: 1px solid var(--color-border);\n  }\n  \n  .news-detail-title {\n    font-size: 2.5rem;\n    margin-bottom: 1rem;\n    color: var(--color-text);\n  }\n  \n  .news-detail-image {\n    width: 100%;\n    height: auto;\n    border-radius: 8px;\n    margin-bottom: 1rem;\n  }\n  \n  .news-detail-content {\n    font-size: 1rem;\n    color: var(--color-text);\n  }\n  \n  .news-detail-footer {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 1rem;\n    font-size: 0.875rem;\n    color: var(--color-secondary);\n  }\n  \n  .news-detail-author,\n  .news-detail-date {\n    margin: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
