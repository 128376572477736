import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Support.css';

interface AvatarStatus {
  id: string;
  status: string;
  name: string;
  photo_url: string;
}

const fetchAvatars = async (): Promise<AvatarStatus[]> => {
  const avatarIds = [
    '85f58e6a-8bfa-49c6-b108-7bbf63796f69',
    '83ccfa7f-a26d-48be-ac12-df47d2a89bac',
    '6daf38cf-d17a-4094-b0af-b41e743f5186',
    'b70c2dfd-13a6-4f7d-89db-5cd1e1896ccc',
    '9f764e0d-2e2a-4242-ab70-bab5ea76664e',
  ];

  try {
    const responses = await Promise.all(
      avatarIds.map(async (id) => {
        const response = await axios.get(`/api/avatar/${id}`);
        return { id, ...response.data };
      })
    );

    console.log('Avatar statuses:', responses);
    return responses;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error fetching avatar info:', error.message);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      } else if (error.request) {
        console.error('Request data:', error.request);
      }
    } else {
      console.error('Unexpected error:', error);
    }
    return [];
  }
};

const sortAvatars = (avatars: AvatarStatus[]): AvatarStatus[] => {
  return avatars
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => (a.status === 'online' && b.status === 'offline' ? -1 : 0));
};

const Support = () => {
  const [avatars, setAvatars] = useState<AvatarStatus[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAvatars();
      setAvatars(sortAvatars(data));
    };

    fetchData();
  }, []);

  return (
    <div style={{ marginTop: '70px' }} className="bg-light-background dark:bg-dark-background text-light-text dark:text-dark-text">
      <div className="container-support">
        <h1 className="title-support">Support Team</h1>
        <div className="avatar-grid">
          {avatars.map((avatar) => (
            <div key={avatar.id} className="avatar-card bg-light-cardBackground dark:bg-dark-cardBackground text-light-text dark:text-dark-text">
              <img src={avatar.photo_url} alt={`${avatar.name}'s avatar`} className="avatar-photo" />
              <div className="avatar-info">
                <strong className={`avatar-name ${avatar.status === 'online' ? 'text-green-500' : 'text-red-500'}`}>{avatar.name}</strong>
                <p className="avatar-status">Online: {avatar.status === 'online' ? 'Yes' : 'No'}</p>
                <p className="avatar-support">Support: Yes</p>
                <p className="avatar-sales">Sales: Yes</p>
                <p className="avatar-languages">Languages: English</p>
                <p className="avatar-email">email: support@example.com</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Support;
