import React, { useState } from 'react';
import './Faq.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const faqDataLeft = [
  {
    question: 'What is Second Life?',
    answer: 'Second Life is a virtual world where users can interact with each other, create content, and explore different environments.',
  },
  {
    question: 'Can I customize my virtual property?',
    answer: 'Yes, you can customize your virtual property using various tools and assets available within Second Life. You can build structures, decorate, and even script interactions.',
  },
];

const faqDataRight = [
  {
    question: 'How do I buy land in Second Life?',
    answer: 'You can buy land in Second Life through the official marketplace or from other users. Ensure you have the necessary funds and permissions before making a purchase.',
  },
  {
    question: 'What are the benefits of owning virtual land?',
    answer: 'Owning virtual land allows you to create personal or business spaces, host events, and potentially earn income by renting or selling the property.',
  },
];

const Faq: React.FC = () => {
  const [openIndexLeft, setOpenIndexLeft] = useState<number | null>(null);
  const [openIndexRight, setOpenIndexRight] = useState<number | null>(null);

  const toggleAnswerLeft = (index: number) => {
    setOpenIndexLeft(openIndexLeft === index ? null : index);
  };

  const toggleAnswerRight = (index: number) => {
    setOpenIndexRight(openIndexRight === index ? null : index);
  };

  return (
    <div style={{ marginTop: '70px' }}>
      <div className="faq-container">
        <div className="faq-title">
          <h1>Frequently Asked Questions</h1>
        </div>
        <div className="faq-columns">
          <div className="faq-column">
            {faqDataLeft.map((item, index) => (
              <div key={index} className="faq-item">
                <div
                  className="faq-question"
                  onClick={() => toggleAnswerLeft(index)}
                >
                  {item.question}
                  {openIndexLeft === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                <div
                  className={`faq-answer ${openIndexLeft === index ? 'open' : ''}`}
                >
                  {openIndexLeft === index && <p>{item.answer}</p>}
                </div>
              </div>
            ))}
          </div>
          <div className="faq-column">
            {faqDataRight.map((item, index) => (
              <div key={index} className="faq-item">
                <div
                  className="faq-question"
                  onClick={() => toggleAnswerRight(index)}
                >
                  {item.question}
                  {openIndexRight === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                <div
                  className={`faq-answer ${openIndexRight === index ? 'open' : ''}`}
                >
                  {openIndexRight === index && <p>{item.answer}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
