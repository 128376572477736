// /Projects/sl-site/frontend/src/pages/web/Home/Slide.tsx

import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import './Slide.css';

const Slide: React.FC = () => {
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (sliderRef.current) {
        sliderRef.current.slickPlay();
      }
    }, 5000);

    const adjustSliderHeight = () => {
      const header = document.querySelector('header');
      const slideContainer = document.querySelector('.slide-container') as HTMLElement | null;

      if (header && slideContainer) {
        const headerHeight = header.offsetHeight;
        const newHeight = window.innerHeight - headerHeight;
        slideContainer.style.height = `${newHeight}px`;
      }
    };

    window.addEventListener('resize', adjustSliderHeight);
    adjustSliderHeight();

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', adjustSliderHeight);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  return (
    <div className="slide-container">
      <Slider ref={sliderRef} {...settings}>
        <div>
          <img src="/imgs/slides/01.jpg" alt="Slide 1" className="slide-image" />
        </div>
        <div>
          <img src="/imgs/slides/02.jpg" alt="Slide 2" className="slide-image" />
        </div>
        <div>
          <img src="/imgs/slides/03.jpg" alt="Slide 3" className="slide-image" />
        </div>
      </Slider>
    </div>
  );
};

export default Slide;
