// /Projects/sl-site/frontend/src/components/PromoSection.tsx

import React from 'react';
import './PromoSection.css';

const PromoSection: React.FC = () => {
  return (
    <div className="promo-section py-16 text-center full-width-bg">
      <div className="content-wrapper promo-content">
        <h2 className="text-4xl font-bold mb-4">Find Your Dream Land in Second Life</h2>
        <p className="text-lg mb-8">Discover the ease of acquiring virtual properties. Our platform offers a wide range of ready-to-build lands where you can create and customize your space in Second Life. Try our complete tool and see how easy it is to start your new virtual life.</p>
        <button className="btn btn-gold font-bold rounded">
          VIEW AVAILABLE LANDS
        </button>
      </div>
    </div>
  );
};

export default PromoSection;
