// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* /Projects/sl-site/frontend/src/components/PromoSection.css */

.promo-section {
  width: 100vw; /* Use 100vw para ocupar toda a largura da viewport */
  margin-left: calc(50% - 50vw); /* Alinhar ao centro */
  padding: 50px;
  margin-top: 20px;
  background-color: var(--promo-background);
  color: var(--promo-text);
}

.promo-button {
  background-color: var(--color-yellow-500);
  color: var(--promo-text);
}

.promo-button:hover {
  background-color: var(--color-yellow-600);
}

.promo-content {
  max-width: 1200px; /* Limitar a largura do conteúdo */
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.promo-content h2{
  color: var(--color-yellow-600);
}`, "",{"version":3,"sources":["webpack://./src/pages/web/Home/PromoSection.css"],"names":[],"mappings":"AAAA,+DAA+D;;AAE/D;EACE,YAAY,EAAE,qDAAqD;EACnE,6BAA6B,EAAE,sBAAsB;EACrD,aAAa;EACb,gBAAgB;EAChB,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;EACE,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,iBAAiB,EAAE,kCAAkC;EACrD,cAAc;EACd,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["/* /Projects/sl-site/frontend/src/components/PromoSection.css */\n\n.promo-section {\n  width: 100vw; /* Use 100vw para ocupar toda a largura da viewport */\n  margin-left: calc(50% - 50vw); /* Alinhar ao centro */\n  padding: 50px;\n  margin-top: 20px;\n  background-color: var(--promo-background);\n  color: var(--promo-text);\n}\n\n.promo-button {\n  background-color: var(--color-yellow-500);\n  color: var(--promo-text);\n}\n\n.promo-button:hover {\n  background-color: var(--color-yellow-600);\n}\n\n.promo-content {\n  max-width: 1200px; /* Limitar a largura do conteúdo */\n  margin: 0 auto;\n  padding: 0 1rem;\n  box-sizing: border-box;\n}\n\n.promo-content h2{\n  color: var(--color-yellow-600);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
