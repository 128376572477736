import React from 'react';
import './AboutUs.css';

const AboutUs: React.FC = () => {
  return (
    <div style={{ marginTop: '70px' }}>
      <div className="about-us-container">
        <section
          className="hero-section"
          style={{
            background: "url('/imgs/places/1200x400.png') no-repeat center center/cover",
            padding: '4rem 2rem',
            textAlign: 'center',
            color: 'white',
          }}
        >
          <div className="hero-content">
            <h1 className="hero-title">About Us</h1>
            <p className="hero-subtitle">Our story, values, and team</p>
          </div>
        </section>

        <section className="story-section">
          <h2 className="section-title">Our Story</h2>
          <p className="section-content">
            Founded in [Year], our mission has always been [Company Mission]. From the beginning, we have been committed to [Core Values] and work tirelessly to achieve [Main Goal].
          </p>
        </section>

        <section className="values-section">
          <h2 className="section-title">Our Values</h2>
          <div className="values-list">
            <div className="value-item">
              <h3>Innovation</h3>
              <p>We constantly seek innovative ways to improve our services and products.</p>
            </div>
            <div className="value-item">
              <h3>Integrity</h3>
              <p>We operate with transparency and ethics in all our actions.</p>
            </div>
            <div className="value-item">
              <h3>Excellence</h3>
              <p>We strive to provide services of the highest quality.</p>
            </div>
          </div>
        </section>

        <section className="team-section">
          <h2 className="section-title">Meet the Team</h2>
          <div className="team-grid">
            <div className="team-member">
              <img src="/imgs/places/150.png" alt="Team Member" className="team-photo" />
              <h3>John Smith</h3>
              <p>CEO</p>
            </div>
            <div className="team-member">
              <img src="/imgs/places/150.png" alt="Team Member" className="team-photo" />
              <h3>Mary Johnson</h3>
              <p>CTO</p>
            </div>
            <div className="team-member">
              <img src="/imgs/places/150.png" alt="Team Member" className="team-photo" />
              <h3>Peter Brown</h3>
              <p>COO</p>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <h2 className="section-title">Contact Us</h2>
          <p className="section-content">Have any questions or want to know more about our company? Feel free to reach out to us!</p>
          <a href="/contact" className="btn btn-gold">Get in Touch</a>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
