// /Projects/sl-site/frontend/src/pages/web/header/Header.tsx

import React, { useState } from 'react';
import { FaExternalLinkAlt, FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ThemeToggle from '../../../components/ThemeToggle';
import './Header.css';
import '../../../styles/Button.css'; // Adicione esta linha para importar a CSS dos botões

interface HeaderProps {
  theme: string;
  toggleTheme: () => void;
}

const Header: React.FC<HeaderProps> = ({ theme, toggleTheme }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="full-width-bg bg-light-background dark:bg-dark-header shadow-md py-2">
      <div className="content-wrapper mx-auto flex justify-between items-center" style={{ height: '70px' }}>
        {/* Logo */}
        <Link to="/" className="text-2xl font-bold text-light-text dark:text-dark-text">
          AeroPage
        </Link>

        {/* Menu for Desktop */}
        <nav className="hidden md:flex space-x-4">
          <Link to="/" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold">Home</Link>
          <Link to="/available-land" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold">Available Land</Link>
          <Link to="/support" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold">Support</Link>
          <Link to="/news" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold">News</Link>
          <Link to="/faq" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold">Faq</Link>
          <Link to="/about-us" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold">About Us</Link>
        </nav>

        {/* External Link Button and Theme Toggle for Large Screens */}
        <div className="hidden md:flex items-center space-x-4">
          <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
          <a 
            href="http://maps.secondlife.com/secondlife/Royal%20Estate/185/213/25"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-gold flex items-center space-x-1"
          >
            <FaExternalLinkAlt />
            <span>Land Office SLurl</span>
          </a>
        </div>

        {/* Menu Icon and Theme Toggle for Small Screens */}
        <div className="flex md:hidden items-center space-x-2">
          <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
          <div onClick={toggleMenu} className="cursor-pointer">
            {menuOpen ? <FaTimes className="text-light-text dark:text-dark-text" /> : <FaBars className="text-light-text dark:text-dark-text" />}
          </div>
        </div>

        {/* Menu for Mobile */}
        {menuOpen && (
          <nav className="absolute top-16 left-0 right-0 bg-light-background dark:bg-dark-header shadow-lg p-4 flex flex-col space-y-4 md:hidden">
            <Link to="/" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold" onClick={toggleMenu}>Home</Link>
            <Link to="/available-land" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold" onClick={toggleMenu}>Available Land</Link>
            <Link to="/support" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold" onClick={toggleMenu}>Support</Link>
            <Link to="/news" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold" onClick={toggleMenu}>News</Link>
            <Link to="/faq" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold" onClick={toggleMenu}>Faq</Link>
            <Link to="/about-us" className="menu-link text-light-text dark:text-dark-text hover:border-b-2 hover:border-gold" onClick={toggleMenu}>About Us</Link>
            <a 
              href="http://maps.secondlife.com/secondlife/Royal%20Estate/185/213/25"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-gold flex items-center space-x-1"
            >
              <FaExternalLinkAlt />
              <span>Land Office SLurl</span>
            </a>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
