import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './BlogPostsSection.css';

interface Post {
  id: number;
  title: string;
  excerpt: string;
  url: string;
  imageUrl: string;
}

const BlogPostsSection: React.FC = () => {
  const posts: Post[] = [
    {
      id: 1,
      title: 'Exploring Virtual Real Estate in Second Life',
      excerpt: 'Discover the potential of virtual real estate in Second Life. Learn how to buy, sell, and manage properties in this virtual world.',
      url: '/blog/exploring-virtual-real-estate',
      imageUrl: '/imgs/places/300x200.png'
    },
    {
      id: 2,
      title: 'Building Your Dream Home in Second Life',
      excerpt: 'A step-by-step guide to building and customizing your dream home in Second Life. From choosing the right land to decorating your space.',
      url: '/blog/building-your-dream-home',
      imageUrl: '/imgs/places/300x200-dreamhome.png'
    },
    {
      id: 3,
      title: 'The Economic Impact of Virtual Worlds',
      excerpt: 'An analysis of how virtual worlds like Second Life impact real-world economies and the opportunities they present for businesses.',
      url: '/blog/economic-impact-of-virtual-worlds',
      imageUrl: '/imgs/places/300x200-Economic.png'
    }
  ];

  return (
    <div className="blog-posts-section py-16">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-8">Latest Blog Posts</h2>
        <div className="posts-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map(post => (
            <div key={post.id} className="post-card p-4 border rounded-lg shadow-md">
              <a href={post.url}>
                <img src={post.imageUrl} alt={post.title} className="mb-4 rounded-lg" />
              </a>
              <div className="post-content">
                <a href={post.url}>
                  <h3 className="text-2xl font-semibold mb-2 hover:underline post-title">{post.title}</h3>
                </a>
                <p className="post-excerpt">{post.excerpt}</p>
                <a href={post.url} className="post-link">
                  Continue Reading <FontAwesomeIcon icon={faArrowRight} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPostsSection;
