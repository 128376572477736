// /Projects/sl-site/frontend/src/pages/News/News.tsx

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './News.css';

interface Post {
  id: number;
  title: string;
  image: string;
  excerpt: string;
  content: string;
  date: string;
  author: string;
}

const News: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      // Temporariamente usando dados fictícios
      const mockData: Post[] = [
        {
          id: 1,
          title: 'Explorando o Futuro da Tecnologia',
          image: '/imgs/places/600x400.png',
          excerpt: 'Uma visão detalhada das inovações tecnológicas que moldarão nosso futuro.',
          content: 'Conteúdo completo do artigo 1...',
          date: '2023-07-01',
          author: 'João Silva'
        },
        {
          id: 2,
          title: 'A Revolução da Inteligência Artificial',
          image: '/imgs/places/600x400.png',
          excerpt: 'Como a IA está transformando indústrias e impactando nossas vidas diárias.',
          content: 'Conteúdo completo do artigo 2...',
          date: '2023-06-25',
          author: 'Maria Oliveira'
        },
        {
          id: 3,
          title: 'Sustentabilidade em Tecnologia',
          image: '/imgs/places/600x400.png',
          excerpt: 'Práticas e inovações que estão ajudando a criar um futuro mais sustentável.',
          content: 'Conteúdo completo do artigo 3...',
          date: '2023-06-15',
          author: 'Pedro Santos'
        }
      ];
      setPosts(mockData);
    };

    fetchPosts();
  }, []);

  return (
    <div style={{ marginTop: '70px' }}>
      <div className="news-container">
        <h1 className="news-title">Latest News</h1>
        <div className="news-grid">
          {posts.map(post => (
            <Link to={`/news/${post.id}`} key={post.id} className="news-card-link">
              <div className="news-card">
                <img src={post.image} alt={post.title} className="news-image" />
                <div className="news-content">
                  <h2 className="news-card-title">{post.title}</h2>
                  <p className="news-card-excerpt">{post.excerpt}</p>
                  <div className="news-card-footer">
                    <span className="news-card-author">{post.author}</span>
                    <span className="news-card-date">{new Date(post.date).toLocaleDateString()}</span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
