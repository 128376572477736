import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../footer/Footer';
import './NewsDetail.css';

interface Post {
  id: number;
  title: string;
  image: string;
  excerpt: string;
  content: string;
  date: string;
  author: string;
}

const NewsDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      // Temporariamente usando dados fictícios
      const mockData: Post[] = [
        {
          id: 1,
          title: 'Explorando o Futuro da Tecnologia',
          image: '/imgs/places/600x400.png',
          excerpt: 'Uma visão detalhada das inovações tecnológicas que moldarão nosso futuro.',
          content: 'Conteúdo completo do artigo 1...',
          date: '2023-07-01',
          author: 'João Silva'
        },
        {
          id: 2,
          title: 'A Revolução da Inteligência Artificial',
          image: '/imgs/places/600x400.png',
          excerpt: 'Como a IA está transformando indústrias e impactando nossas vidas diárias.',
          content: 'Conteúdo completo do artigo 2...',
          date: '2023-06-25',
          author: 'Maria Oliveira'
        },
        {
          id: 3,
          title: 'Sustentabilidade em Tecnologia',
          image: '/imgs/places/600x400.png',
          excerpt: 'Práticas e inovações que estão ajudando a criar um futuro mais sustentável.',
          content: 'Conteúdo completo do artigo 3...',
          date: '2023-06-15',
          author: 'Pedro Santos'
        }
      ];

      const post = mockData.find(p => p.id === parseInt(id ?? ''));
      setPost(post || null);
    };

    fetchPost();
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ marginTop: '70px' }}>
      <div className="news-detail-container">
        <h1 className="news-detail-title">{post.title}</h1>
        <img src={post.image} alt={post.title} className="news-detail-image" />
        <div className="news-detail-content">
          <p>{post.content}</p>
          <div className="news-detail-footer">
            <span className="news-detail-author">By {post.author}</span>
            <span className="news-detail-date">{new Date(post.date).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
      <Footer /> {/* Adicionando o Footer */}
    </div>
  );
};

export default NewsDetail;
