// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* /Projects/sl-site/frontend/src/components/Footer.css */

.footer {
  padding: 1rem 0;
  margin-top: 2rem;
  width: 100%;
  background-color: var(--color-background);
}

.footer-bottom {
  margin-top: 0;
  text-align: center;
}

.footer-links {
  margin-bottom: 0.5rem;
}

.footer-links a {
  color: var(--color-text);
  margin-right: 0.5rem; /* Ajuste da margem entre os links */
  font-size: 0.875rem; /* Tamanho da fonte menor */
  transition: color 0.3s;
}

.footer-links a:hover {
  color: var(--color-primary);
}

.footer p {
  color: var(--color-text);
  font-size: 0.875rem; /* Tamanho da fonte menor */
  margin: 0; /* Remover margem do parágrafo */
}

.footer-message {
  text-align: right;
  margin-left: auto;
  position: absolute;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/web/footer/Footer.css"],"names":[],"mappings":"AAAA,yDAAyD;;AAEzD;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,oBAAoB,EAAE,oCAAoC;EAC1D,mBAAmB,EAAE,2BAA2B;EAChD,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;EACxB,mBAAmB,EAAE,2BAA2B;EAChD,SAAS,EAAE,gCAAgC;AAC7C;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":["/* /Projects/sl-site/frontend/src/components/Footer.css */\n\n.footer {\n  padding: 1rem 0;\n  margin-top: 2rem;\n  width: 100%;\n  background-color: var(--color-background);\n}\n\n.footer-bottom {\n  margin-top: 0;\n  text-align: center;\n}\n\n.footer-links {\n  margin-bottom: 0.5rem;\n}\n\n.footer-links a {\n  color: var(--color-text);\n  margin-right: 0.5rem; /* Ajuste da margem entre os links */\n  font-size: 0.875rem; /* Tamanho da fonte menor */\n  transition: color 0.3s;\n}\n\n.footer-links a:hover {\n  color: var(--color-primary);\n}\n\n.footer p {\n  color: var(--color-text);\n  font-size: 0.875rem; /* Tamanho da fonte menor */\n  margin: 0; /* Remover margem do parágrafo */\n}\n\n.footer-message {\n  text-align: right;\n  margin-left: auto;\n  position: absolute;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
