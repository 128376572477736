// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* /frontend/src/pages/web/Faq/Faq.css */

.faq-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.faq-title {
  text-align: center;
  margin-bottom: 2rem;
}

.faq-title h1 {
  font-size: 2.5rem;
  color: var(--color-text);
}

.faq-columns {
  display: flex;
  gap: 2rem;
}

.faq-column {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.faq-item {
  background-color: var(--color-card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid var(--color-border);
}

.faq-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.faq-question {
  background-color: var(--color-background);
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, padding 0.5s ease-out;
}

.faq-answer p {
  margin: 0;
  padding: 0 1rem;
}

.faq-answer.open {
  max-height: 10rem;
  padding: 1rem;
}

@media (max-width: 768px) {
  .faq-columns {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/web/Faq/Faq.css"],"names":[],"mappings":"AAAA,wCAAwC;;AAExC;EACE,iBAAiB;EACjB,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,8CAA8C;EAC9C,kBAAkB;EAClB,wCAAwC;EACxC,qDAAqD;EACrD,qCAAqC;AACvC;;AAEA;EACE,2BAA2B;EAC3B,0CAA0C;AAC5C;;AAEA;EACE,yCAAyC;EACzC,eAAe;EACf,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,2DAA2D;AAC7D;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":["/* /frontend/src/pages/web/Faq/Faq.css */\n\n.faq-container {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding-bottom: 2rem;\n}\n\n.faq-title {\n  text-align: center;\n  margin-bottom: 2rem;\n}\n\n.faq-title h1 {\n  font-size: 2.5rem;\n  color: var(--color-text);\n}\n\n.faq-columns {\n  display: flex;\n  gap: 2rem;\n}\n\n.faq-column {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n}\n\n.faq-item {\n  background-color: var(--color-card-background);\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  transition: transform 0.3s ease, box-shadow 0.3s ease;\n  border: 1px solid var(--color-border);\n}\n\n.faq-item:hover {\n  transform: translateY(-5px);\n  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);\n}\n\n.faq-question {\n  background-color: var(--color-background);\n  cursor: pointer;\n  padding: 1rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-radius: 8px;\n}\n\n.faq-answer {\n  max-height: 0;\n  overflow: hidden;\n  transition: max-height 0.5s ease-out, padding 0.5s ease-out;\n}\n\n.faq-answer p {\n  margin: 0;\n  padding: 0 1rem;\n}\n\n.faq-answer.open {\n  max-height: 10rem;\n  padding: 1rem;\n}\n\n@media (max-width: 768px) {\n  .faq-columns {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
