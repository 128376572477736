import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faList, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './AvailableLand.css';

interface Land {
  title: string;
  photo: string;
  price: string;
  time: string;
  region: string;
  slurl: string;
  prims: string;
  description: string;
}

const AvailableLand: React.FC = () => {
  const [lands, setLands] = useState<Land[]>([]);
  const [filteredLands, setFilteredLands] = useState<Land[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedPrims, setSelectedPrims] = useState('');
  const [view, setView] = useState<'grid' | 'list'>('grid');

  useEffect(() => {
    const fetchLands = async () => {
      try {
        const response = await axios.get('/api/availableLands');
        setLands(response.data);
        setFilteredLands(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchLands();
  }, []);

  useEffect(() => {
    filterLands();
  }, [searchTerm, selectedRegion, selectedPrims, lands]);

  const filterLands = () => {
    let filtered = lands;

    if (searchTerm) {
      filtered = filtered.filter(land =>
        land.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedRegion) {
      filtered = filtered.filter(land => land.region === selectedRegion);
    }

    if (selectedPrims) {
      filtered = filtered.filter(land => land.prims === selectedPrims);
    }

    setFilteredLands(filtered);
  };

  const extractCoordinates = (slurl: string): { x: number, y: number } => {
    const regex = /secondlife:\/\/[^\/]+\/(\d+)\/(\d+)\/\d+/;
    const match = slurl.match(regex);
    if (match) {
      return { x: parseInt(match[1]), y: parseInt(match[2]) };
    }
    return { x: 0, y: 0 };
  };

  const regions = Array.from(new Set(lands.map(land => land.region)));
  const prims = Array.from(new Set(lands.map(land => land.prims)));

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4 available-land-title">Available Land Page</h1>
      <div className="filter-bar">
        <input
          type="text"
          placeholder="Search by title"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <select
          value={selectedRegion}
          onChange={e => setSelectedRegion(e.target.value)}
          className="filter-select"
        >
          <option value="">All Regions</option>
          {regions.map((region, index) => (
            <option key={index} value={region}>{region}</option>
          ))}
        </select>
        <select
          value={selectedPrims}
          onChange={e => setSelectedPrims(e.target.value)}
          className="filter-select"
        >
          <option value="">All Prims</option>
          {prims.map((prims, index) => (
            <option key={index} value={prims}>{prims}</option>
          ))}
        </select>
        <div className="view-buttons">
          <button onClick={() => setView('grid')} className={`view-button ${view === 'grid' ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faTh} />
          </button>
          <button onClick={() => setView('list')} className={`view-button ${view === 'list' ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faList} />
          </button>
        </div>
      </div>
      {filteredLands.length > 0 ? (
        view === 'grid' ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 available-land-grid">
            {filteredLands.map((land, index) => {
              const { x, y } = extractCoordinates(land.slurl);
              return (
                <div key={index} className="p-4 border rounded shadow available-land-card grid">
                  <div className="map-container">
                    <img src={land.photo} alt={`Map of ${land.region}`} className="available-land-photo" />
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="map-marker" style={{ top: `${(256 - y) / 256 * 100}%`, left: `${x / 256 * 100}%` }} />
                  </div>
                  <h2 className="text-xl font-semibold available-land-title">{land.title}</h2>
                  <p className="available-land-price"><strong>Price:</strong> {land.price}</p>
                  <p className="available-land-time"><strong>Time:</strong> {land.time}</p>
                  <p className="available-land-region"><strong>Region:</strong> {land.region}</p>
                  <p className="available-land-prims"><strong>Prims:</strong> {land.prims}</p>
                  <p className="available-land-description"><strong>Description:</strong> {land.description}</p>
                  <a href={land.slurl} target="_blank" rel="noopener noreferrer" className="available-land-teleport-link">
                    <button className="btn btn-gold block">Teleport</button>
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          <table className="available-land-list">
            <thead>
              <tr>
                <th>Region</th>
                <th>Title</th>
                <th>Price</th>
                <th>Size</th>
                <th>Prims</th>
                <th>Rating</th>
                <th>Rules</th>
                <th>Teleport</th>
              </tr>
            </thead>
            <tbody>
              {filteredLands.map((land, index) => (
                <tr key={index}>
                  <td>{land.region}</td>
                  <td>{land.title}</td>
                  <td>{land.price}</td>
                  <td>{land.time}</td>
                  <td>{land.prims}</td>
                  <td>{land.description}</td>
                  <td>
                    <a href={land.slurl} target="_blank" rel="noopener noreferrer">
                      <button className="available-land-teleport-button">Teleport</button>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      ) : (
        <p className="no-available-lands">No available lands found.</p>
      )}
    </div>
  );
};

export default AvailableLand;
