// /Projects/sl-site/frontend/src/components/ThemeToggle.tsx

import React, { useEffect } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';

interface ThemeToggleProps {
  theme: string;
  toggleTheme: () => void;
}

const ThemeToggle: React.FC<ThemeToggleProps> = ({ theme, toggleTheme }) => {
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <button onClick={toggleTheme} className="focus:outline-none">
      {theme === 'light' ? (
        <FaSun className="text-yellow-500" />
      ) : (
        <FaMoon className="text-gray-300" />
      )}
    </button>
  );
};

export default ThemeToggle;
