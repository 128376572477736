// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* /Projects/sl-site/frontend/src/components/Slide.css */

.slide-container {
    width: 100vw; /* 100% da largura da viewport */
    height: calc(100vh - 60px); /* 100% da altura da viewport menos a altura do header */
    margin: 0; /* Remover margens */
    overflow: hidden; /* Garantir que o conteúdo extra não apareça */
}

@media (max-width: 767px) {
    .slide-container {
        height: 50vh; /* Ajustar a altura do slide em dispositivos móveis */
    }
}

.slide-container .slick-slide {
    display: flex; /* Garantir que o conteúdo do slide ocupe toda a altura */
    align-items: center;
    justify-content: center;
}

.slide-container .slick-slide img {
    width: 100vw; /* Ocupa toda a largura da viewport */
    height: calc(100vh - 60px); /* Ajusta a altura conforme a viewport */
    object-fit: cover; /* Garante que a imagem cubra o slide */
}
`, "",{"version":3,"sources":["webpack://./src/pages/web/Home/Slide.css"],"names":[],"mappings":"AAAA,wDAAwD;;AAExD;IACI,YAAY,EAAE,gCAAgC;IAC9C,0BAA0B,EAAE,wDAAwD;IACpF,SAAS,EAAE,oBAAoB;IAC/B,gBAAgB,EAAE,8CAA8C;AACpE;;AAEA;IACI;QACI,YAAY,EAAE,qDAAqD;IACvE;AACJ;;AAEA;IACI,aAAa,EAAE,yDAAyD;IACxE,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY,EAAE,qCAAqC;IACnD,0BAA0B,EAAE,wCAAwC;IACpE,iBAAiB,EAAE,uCAAuC;AAC9D","sourcesContent":["/* /Projects/sl-site/frontend/src/components/Slide.css */\n\n.slide-container {\n    width: 100vw; /* 100% da largura da viewport */\n    height: calc(100vh - 60px); /* 100% da altura da viewport menos a altura do header */\n    margin: 0; /* Remover margens */\n    overflow: hidden; /* Garantir que o conteúdo extra não apareça */\n}\n\n@media (max-width: 767px) {\n    .slide-container {\n        height: 50vh; /* Ajustar a altura do slide em dispositivos móveis */\n    }\n}\n\n.slide-container .slick-slide {\n    display: flex; /* Garantir que o conteúdo do slide ocupe toda a altura */\n    align-items: center;\n    justify-content: center;\n}\n\n.slide-container .slick-slide img {\n    width: 100vw; /* Ocupa toda a largura da viewport */\n    height: calc(100vh - 60px); /* Ajusta a altura conforme a viewport */\n    object-fit: cover; /* Garante que a imagem cubra o slide */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
