// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-support {
  max-width: 1000px;
  margin: auto;
  text-align: center;
}

.title-support {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.avatar-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.avatar-card {
  padding: 1rem;
  border-radius: 8px;
  text-align: left;
  display: flex;
  flex-direction: row;
  transition: background-color 0.3s, color 0.3s;
}

.avatar-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
}

.avatar-info {
  flex: 1 1;
}

.avatar-name {
  font-size: 1.2rem;
}

.avatar-status,
.avatar-support,
.avatar-sales,
.avatar-languages,
.avatar-email {
  margin: 0.2rem 0;
}

@media (max-width: 767px) {
  .avatar-grid {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }

  .avatar-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 1rem;
  }

  .avatar-photo {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .avatar-info {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/web/Support/Support.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;;;;EAKE,gBAAgB;AAClB;;AAEA;EACE;IACE,0BAA0B;IAC1B,eAAe;EACjB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".container-support {\n  max-width: 1000px;\n  margin: auto;\n  text-align: center;\n}\n\n.title-support {\n  font-size: 3rem;\n  margin-bottom: 1rem;\n}\n\n.avatar-grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 1rem;\n}\n\n.avatar-card {\n  padding: 1rem;\n  border-radius: 8px;\n  text-align: left;\n  display: flex;\n  flex-direction: row;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.avatar-photo {\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  margin-right: 1rem;\n}\n\n.avatar-info {\n  flex: 1;\n}\n\n.avatar-name {\n  font-size: 1.2rem;\n}\n\n.avatar-status,\n.avatar-support,\n.avatar-sales,\n.avatar-languages,\n.avatar-email {\n  margin: 0.2rem 0;\n}\n\n@media (max-width: 767px) {\n  .avatar-grid {\n    grid-template-columns: 1fr;\n    padding: 0 1rem;\n  }\n\n  .avatar-card {\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    margin: 0 1rem;\n  }\n\n  .avatar-photo {\n    margin-right: 0;\n    margin-bottom: 1rem;\n  }\n\n  .avatar-info {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
