import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer bg-light-footer dark:bg-dark-footer text-light-text dark:text-dark-text py-4">
      <div className="container mx-auto flex justify-center items-center relative">
        <div className="footer-bottom text-center">
          <div className="footer-links mb-2">
            <a href="/privacy-policy" className="hover:underline mr-2 text-light-text dark:text-dark-text">Privacy Policy</a>
            <a href="/terms-of-service" className="hover:underline text-light-text dark:text-dark-text">Terms of Service</a>
          </div>
          <p className="text-sm">&copy; 2024 Your Company Name. All rights reserved.</p>
        </div>
        <div className="footer-message absolute right-0">
          <p className="text-sm">Made with <span role="img" aria-label="heart">❤️</span> by Maycon Bekkers</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
