import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './pages/web/header/Header';
import Home from './pages/Home';
import AvailableLand from './pages/web/AvailableLand/AvailableLand';
import Support from './pages/web/Support/Support';
import News from './pages/web/News/News';
import NewsDetail from './pages/web/News/NewsDetail';
import Faq from './pages/web/Faq/Faq';
import AboutUs from './pages/web/AboutUs/AboutUs';
import Footer from './pages/web/footer/Footer';
import ThemeToggle from './components/ThemeToggle';
import './styles/Global.css';
import './styles/Button.css';

const App: React.FC = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <Router>
      <div className={`min-h-screen flex flex-col ${theme}`}>
        <Header theme={theme} toggleTheme={toggleTheme} />
        <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/available-land" element={<AvailableLand />} />
            <Route path="/support" element={<Support />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:id" element={<NewsDetail />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/about-us" element={<AboutUs />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
