// /Projects/sl-site/frontend/src/components/Services.tsx

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Services.css';
import '../../../utils/fontAwesome';

const Services: React.FC = () => {
  return (
    <div className="full-width-bg bg-light-background dark:bg-dark-background">
      <div className="content-wrapper mx-auto p-8 text-light-text dark:text-dark-text">
        <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2>
        <div className="services-flex flex-wrap">
          <div className="service-box bg-light-cardBackground dark:bg-dark-cardBackground text-light-text dark:text-dark-text">
            <div className="icon-container">
              <FontAwesomeIcon icon="home" className="icon text-light-primary dark:text-dark-buttonPrimaryBackgroundColor" />
            </div>
            <div className="content">
              <h3 className="text-xl font-semibold">GETTING STARTED</h3>
              <p className="text-light-secondary dark:text-dark-secondary">
                Feel free to check our available lands list to find your happiness.
              </p>
              <a href="/available-land" className="read-more text-light-linkColor dark:text-dark-linkColor hover:text-light-linkColorHover dark:hover:text-dark-linkColorHover">
                Read more <span className="arrow">→</span>
              </a>
            </div>
          </div>
          <div className="service-box bg-light-cardBackground dark:bg-dark-cardBackground text-light-text dark:text-dark-text">
            <div className="icon-container">
              <FontAwesomeIcon icon="bullseye" className="icon text-light-primary dark:text-dark-buttonPrimaryBackgroundColor" />
            </div>
            <div className="content">
              <h3 className="text-xl font-semibold">OUR GOAL</h3>
              <p className="text-light-secondary dark:text-dark-secondary">
                Our goal is your satisfaction! Our staff do the best to give you what you want.
              </p>
              <a href="#" className="read-more text-light-linkColor dark:text-dark-linkColor hover:text-light-linkColorHover dark:hover:text-dark-linkColorHover">
                Read more <span className="arrow">→</span>
              </a>
            </div>
          </div>
          <div className="service-box bg-light-cardBackground dark:bg-dark-cardBackground text-light-text dark:text-dark-text">
            <div className="icon-container">
              <FontAwesomeIcon icon="life-ring" className="icon text-light-primary dark:text-dark-buttonPrimaryBackgroundColor" />
            </div>
            <div className="content">
              <h3 className="text-xl font-semibold">SUPPORT</h3>
              <p className="text-light-secondary dark:text-dark-secondary">
                Need Help? We offer Customer service. Submit a request or Teleport to our main office in world.
              </p>
              <a href="#" className="read-more text-light-linkColor dark:text-dark-linkColor hover:text-light-linkColorHover dark:hover:text-dark-linkColorHover">
                Read more <span className="arrow">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
