// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* /Projects/sl-site/frontend/src/styles/Button.css */

.btn {
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 600;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 123 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 86 179 / var(--tw-bg-opacity));
}

.btn-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(108 117 125 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(73 80 87 / var(--tw-bg-opacity));
}

.btn-accent {
  --tw-bg-opacity: 1;
  background-color: rgb(23 162 184 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn-accent:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(19 132 150 / var(--tw-bg-opacity));
}

.btn-gold {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.btn-gold:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}


button, a {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

button.dark, a.dark {
  background-color: var(--colors-dark-buttonPrimaryBackgroundColor);
}

button.dark:hover, a.dark:hover {
  background-color: var(--colors-dark-buttonPrimaryBackgroundColorHover);
}
`, "",{"version":3,"sources":["webpack://./src/styles/Button.css"],"names":[],"mappings":"AAAA,qDAAqD;;AAGnD;EAAA,qBAAuF;EAAvF,kBAAuF;EAAvF,mBAAuF;EAAvF,mBAAuF;EAAvF,sBAAuF;EAAvF,gBAAuF;EAAvF,6EAAuF;EAAvF,iGAAuF;EAAvF,uGAAuF;EAAvF,gKAAuF;EAAvF,wJAAuF;EAAvF,iLAAuF;EAAvF,0BAAuF;EAAvF;AAAuF;;AAIvF;EAAA,kBAAwD;EAAxD,uDAAwD;EAAxD,oBAAwD;EAAxD;AAAwD;;AAAxD;EAAA,kBAAwD;EAAxD;AAAwD;;AAIxD;EAAA,kBAA4D;EAA5D,yDAA4D;EAA5D,oBAA4D;EAA5D;AAA4D;;AAA5D;EAAA,kBAA4D;EAA5D;AAA4D;;AAI5D;EAAA,kBAAsD;EAAtD,wDAAsD;EAAtD,oBAAsD;EAAtD;AAAsD;;AAAtD;EAAA,kBAAsD;EAAtD;AAAsD;;AAItD;EAAA,oBAAiB;EAAjB,gDAAiB;EACjB,kBAAkB;EAClB;AAFiB;;AAKnB;EACE,kBAAkB;EAClB,uDAAuD;AACzD;;;AAGA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;EACZ,cAAc;EACd,oBAAoB;EACpB,uBAAuB;EACvB,qBAAqB;EACrB,6CAA6C;AAC/C;;AAEA;EACE,iEAAiE;AACnE;;AAEA;EACE,sEAAsE;AACxE","sourcesContent":["/* /Projects/sl-site/frontend/src/styles/Button.css */\n\n.btn {\n  @apply px-4 py-2 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out;\n}\n\n.btn-primary {\n  @apply bg-primary-light text-white hover:bg-primary-dark;\n}\n\n.btn-secondary {\n  @apply bg-secondary-light text-white hover:bg-secondary-dark;\n}\n\n.btn-accent {\n  @apply bg-accent-light text-white hover:bg-accent-dark;\n}\n\n.btn-gold {\n  @apply text-white;\n  --tw-bg-opacity: 1;\n  background-color: rgb(234 179 8 / var(--tw-bg-opacity));\n}\n\n.btn-gold:hover {\n  --tw-bg-opacity: 1;\n  background-color: rgb(202 138 4 / var(--tw-bg-opacity));\n}\n\n\nbutton, a {\n  display: inline-flex;\n  align-items: center;\n  gap: 0.25rem;\n  color: #ffffff;\n  padding: 0.5rem 1rem;\n  border-radius: 0.375rem;\n  text-decoration: none;\n  transition: background-color 0.3s, color 0.3s;\n}\n\nbutton.dark, a.dark {\n  background-color: var(--colors-dark-buttonPrimaryBackgroundColor);\n}\n\nbutton.dark:hover, a.dark:hover {\n  background-color: var(--colors-dark-buttonPrimaryBackgroundColorHover);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
